import { getFetcher } from '@/utils/auth'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'

export const useCatalogSubscribed = (catalogId) => {
    const url = `catalog-api/catalog/${catalogId}?includeEntities=["userAction","user"]`

    const { data: session } = useSession()
    const token = session?.token

    const { data, error, isValidating, mutate } = useSWR(
        token && catalogId ? [url, token] : null,
        getFetcher,
        { revalidateOnFocus: false }
    )

    const followers = data?.data?.meta?.aggregations?.followers
    const likes = data?.data?.meta?.aggregations?.likes || 0
    const dislikes = data?.data?.meta?.aggregations?.dislikes || 0
    const comments = data?.data?.meta?.aggregations?.comments || 0

    const action = data?.data?.meta?.userActions.find(
        (a) => a.action === 'follow'
    )

    const loading = !error && isValidating

    return {
        data,
        error,
        isValidating,
        loading,
        mutate,
        subscribed:
            action?.actionValue == '1.0' || action?.actionValue == '1' || false,
        likes,
        dislikes,
        comments,
        followers,
    }
}
