import {
    INITIALIZE_CONTENT_PAGE,
    RESET_CONTENT_PAGE,
    UPDATE_CONTENT_ELAPSED_TIME,
    UPDATE_CONTENT_PAGE,
    SET_CURRENT_CONTENT_TO_BE_SHARED,
} from '@/store/types/content'

export const initializeContentPage = (payload) => ({
    type: INITIALIZE_CONTENT_PAGE,
    payload,
})

export const updateContentPage = (payload) => ({
    type: UPDATE_CONTENT_PAGE,
    payload,
})

export const resetContentPage = () => ({
    type: RESET_CONTENT_PAGE,
})

export const updateContentElapsedTime = (payload) => ({
    type: UPDATE_CONTENT_ELAPSED_TIME,
    payload,
})

export const setCurrentContentToBeShared = (payload) => ({
    type: SET_CURRENT_CONTENT_TO_BE_SHARED,
    payload,
})
